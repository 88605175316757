<template>
  <!--  <router-view />-->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>

<script lang="ts" setup>
  console.log('my config env: ', import.meta.env);
</script>
<style>
  .ant-message .anticon {
    position: relative;
    top: -3px !important;
  }

  .full-modal {
    width: 100% !important;
    height: 95% !important;
    top: 0 !important;
    .ant-modal-content {
      width: 100%;
      height: 100%;
    }
  }
</style>
